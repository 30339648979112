import React from 'react'
import {liveHolder} from '../styles/pages.module.scss'
import { Helmet } from 'react-helmet'
import {motion} from 'framer-motion'
import Seo from '../components/utilities/Seo'


export default function LivePage() {
  const variants = {
    pre: {
      opacity: 0,

    },
    visible: {
      opacity:1,
      transition: {
        delay:1,
        type:'tween',
        duration:1
      }
    }
  }

  return (
    <article className={liveHolder}>
      <Helmet>
          <script src="https://widget.bandsintown.com/main.min.js" type="text/javascript" />
      </Helmet>
      <Seo 
        title="Live"
        description="Eliza Shaddad live tour dates"
      />
      <motion.div className="bandsintownwidget" variants={variants} initial="pre" animate="visible">
        {/* <script charset="utf-8" src="https://widget.bandsintown.com/main.min.js"></script> */}
        <a className="bit-widget-initializer"
        data-artist-name="eliza shaddad"
        data-display-local-dates="false"
        data-display-past-dates="false" 
        data-auto-style="false" 
        data-text-color="#000" 
        data-link-color="#000" 
        data-background-color="rgba(241,235,229,0)" 
        data-display-limit="15" 
        data-link-text-color="#fff" 
        data-display-lineup="false" 
        data-display-play-my-city="true" 
        data-separator-color="rgba(255, 255, 255, 0.5)" 
        data-display-start-time="false"></a>
      </motion.div>
      <div><p>Dates not showing? <a href="https://www.bandsintown.com/a/1065238-eliza-shaddad" style={{textDecoration:'underline'}}>Head to my Bands In Town page</a></p></div>
    </article>
  )
}
